.c-page {
  background: url(../img/background-pattern.png) 50% -280px repeat $color-white;
  position: relative;
  padding-top: 80px;
  min-width: 960px;
  overflow: hidden;

  .is-ie & {
    background-image: url(../img/background-pattern.png);
  }

  @media (max-width: $breakpoint-1 + 20px) {
    min-width: 0;
  }

  @media (max-width: $breakpoint-3) {

    &:not(.c-page--top){
      padding-top: 60px;
    }
  }

  @media (max-width: 600px) {

    &:not(.c-page--top){
      padding-top: 60px;

      .admin-bar & {
        padding-top: 14px;
      }
    }
  }

  // @media (max-width: $breakpoint-4) {
  //   background-size: calc(1200px * .9);
  // }

  // @media (max-width: 820px) {
  //   background-size: calc(1200px * .8);
  // }

}

.c-page--top {
  padding-top: 5170px;
}

.c-header {
  background-color: rgba($color-white, .95);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: index($z, header);

  .admin-bar & {
    top: 32px;

    @media (max-width: 782px) {
      top: 46px;
    }

    @media (max-width: 600px) {
      top: 0;
    }
  }

  @media (max-width: $breakpoint-3 ) {
    // position: absolute;
    padding: 5px;
  }
}

.c-header__layout {
  align-items: center;
}

.c-header__logo {
  max-width: 100%;

  @media (max-width: $breakpoint-3) {
    // padding-left: 100px;
  }
}

.c-global-nav-opener {
  display: none;

  @media (max-width: $breakpoint-3) {
    display: block;
    font-size: 30px;
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
    line-height: 1;
    height: 1em;
  }
}

.c-global-nav {
  padding: 0;
  font-size: 1.4rem;
  font-weight: 500;
  flex-wrap: nowrap;
  justify-content: flex-end;
  z-index: index($z, global-nav);
  @include o-grid-gap(8px);

  @media (max-width: $breakpoint-1 + 20px) {
    font-size: 1.2rem;
  }

  @media (max-width: 860px) {
    @include o-grid-gap(0);
    font-size: 1.4rem;
    position: fixed;
    flex-direction: column;
    justify-content: flex-start;
    background-color: $color-white;
    top: 0;
    right: auto;
    left: 0;
    width: 70vw;
    height: 100vh;
    overflow: auto;
    box-shadow: 0 0 10px rgba(#000, .2);
    transition: transform .2s;
    transform: translateX(-70vw);

    [aria-expanded='true'] & {
      transform: translateX(0);
    }
  }
}

.c-global-nav__link {
  display: block;
  height: 80px;
  display: flex;
  align-items: center;
  padding: 5px;
  text-decoration: none;
  color: $color-text;
  transition: all .2s;
  position: relative;

  &::after {
    content: '';
    display: block;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    transition: all .2s;
    background-color: $color-primary;
    position: absolute;
  }

  &:hover,
  &[aria-selected='true'] {
    color: $color-primary;
    text-decoration: none;

    &::after {
      height: 4px;
    }
  }

  @media (max-width: $breakpoint-3) {
    height: auto;
    padding: 1em .5em 1em 1em;

    &::after {
      left: 0;
      top: 0;
      height: 100%;
      width: 0;
    }

    &:hover,
    &[aria-selected='true'] {

      &::after {
        left: 0;
        top: 0;
        height: 100%;
        width: 5px;
      }
    }
  }
}

.c-top-section {
  text-align: center;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  width: 960px;
  transform-origin: 50% 0;

  @media (max-width: $breakpoint-1) {
    margin-left: calc((-960px + 100vw) / 2);
  }
}

.c-top-section--intro {
  top: 80px;

  @media (max-width: $breakpoint-1) {
    top: calc( 100vw * 80 / 960 );
  }

  @media (max-width: 440px) {
    transform: scale(.9);
  }

  @media (max-width: 400px) {
    transform: scale(.8);
  }

  @media (max-width: 360px) {
    transform: scale(.7);
  }
}

.c-top-section--next {
  top: 630px;

  @media (max-width: 740px) {
    transform: scale(.9);
    top: 680px;
  }

  @media (max-width: 680px) {
    transform: scale(.8);
    top: 730px;
  }

  @media (max-width: 600px) {
    transform: scale(.7);
    top: 780px;
  }

  @media (max-width: 520px) {
    transform: scale(.6);
    top: 850px;
  }

  @media (max-width: 450px) {
    transform: scale(.5);
    top: 930px;
  }

  @media (max-width: 380px) {
    transform: scale(.4);
  }
}

.c-top-section--about {
  top: 1330px;

  @media (max-width: $breakpoint-4) {
    top: calc(1330px * .95);
  }
}

.c-top-section--service {
  top: 2530px;
}

.c-top-section--recruit {
  top: 3730px;
}

.c-top-section--news {
  top: 4930px;
}

.c-top-service__photo {
  width: 700px;
  height: 700px;
  position: absolute;

    @media (max-width: $breakpoint-2) {
      width: 400px;
      height: auto;
      margin: auto;
    }
}

.c-top-service__photo--type-1 {
  left: -270px;
  top: -160px;

  @media (max-width: $breakpoint-2) {
    left: 0;
    top: 590px;
    right: 0;
    transform: translate(-50%, -50%);
  }
}

.c-top-service__photo--type-2 {
  left: 0;
  right: 0;
  margin: auto;
  top: 240px;

  @media (max-width: $breakpoint-2) {
    left: 0;
    top: 590px;
    right: 0;
  }
}

.c-top-service__photo--type-3 {
  right: -270px;
  top: -160px;

  @media (max-width: $breakpoint-2) {
    left: 0;
    top: 590px;
    right: 0;
    transform: translate(50%, -50%);
  }
}

.c-top-section-dia {
  position: absolute;
  background-color: rgba($color-white, .9);
  width: 566px;
  height: 566px;
  top: -93px;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(45deg);
}

.c-top-section-dia__inner {
  transform: rotate(-45deg);
  padding-top: 21px;
}

.c-top-intro__logo {
  width: 231px;
  height: auto;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 80px;
}

.c-top-intro__title {
  width: 377px;
  height: 177px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 177px;
}

.c-top-intro__charactor {
  position: absolute;
  left: 195px;
  top: -1px;
}

.c-top-intro__text {
  position: absolute;
  top: 329px;
  font-size: 1.8rem;
  margin: 0;
  width: 100%;
}

.c-top-intro__link {
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 473px;
}

.c-top-intro__logo-part {
  position: absolute;
  left: 365px;
  top: 120px;
}

.c-scroll-sign {
  text-align: center;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.c-scroll-sign__icon {
  display: block;
  font-size: 8rem;
  height: .8em;
  line-height: 1;
  vertical-align: middle;
}

.c-top-next__puzzle {
  background-size: contain;
  color: $color-white;
  // display: flex;
  font-size: 2.4rem;
  height: 480px;
  justify-content: center;
  padding-top: 160px;
  position: absolute;
  text-align: center;
  text-decoration: none;
  width: 430px;

  &:hover {
    text-decoration: none;
  }
}

.c-top-next__puzzle--yellow {
  background-image: url(../img/puzzle-piece-yellow.svg);
  top: 0;
  left: 23px;
}

.c-top-next__puzzle--red {
  background-image: url(../img/puzzle-piece-red.svg);
  top: 243px;
  left: 0;
  right: 0;
  margin: auto;
}

.c-top-next__puzzle--blue {
  background-image: url(../img/puzzle-piece-blue.svg);
  top: 0;
  right: 23px;
}

.c-top-next__puzzle-link {
  color: $color-white;

  &:hover {
    text-decoration: none;
  }
}

.c-heading {
  text-align: center;

  &::after {
    content: url(../img/x.svg);
    display: block;
    margin: 26px auto 0;
  }
}

.c-heading__title {
  font-size: 6.4rem;
  font-weight: bold;
  display: block;

  @media (max-width: $breakpoint-2) {
    font-size: 3.6rem;
  }
}

.c-heading__body {
  font-size: 1.8rem;
  font-weight: 500;
  display: block;

  @media (max-width: $breakpoint-2) {
    font-size: 1.6rem;
  }
}

.c-heading-2 {
  font-weight: bold;
  font-size: 2.4rem;
  border-bottom: 2px solid $color-primary;
  padding-bottom: 0.5em;
  margin-top: 1.5em;

  @media (max-width: $breakpoint-2) {
    font-size: 2rem;
  }
}

.c-heading-3 {
  font-size: 2rem;
  font-weight: bold;
  background-color: $color-gray-4;
  padding: .5em;
  border-left: 5px solid $color-primary;
  margin-top: 1.5em;
}

.c-heading-4 {
  font-size: 1.8rem;
  font-weight: bold;
  margin-top: 1.5em;
  position: relative;
  padding-left: 1.5em;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    width: 1em;
    height: 1em;
    top: 0.25em;
    border-radius: 50%;
    border: 3px solid $color-primary;
  }
}

.c-text-featured {
  text-align: center;
  font-size: 2.4rem;
  font-weight: 500;

  @media (max-width: $breakpoint-2) {
    font-size: 2rem;
  }
}

.c-text-featured__strong {
  font-size: 3.6rem;
  color: $color-primary;
}

.c-text-featured-2 {
  margin-top: 0.5em;
  font-size: 3.6rem;
  font-weight: 500;

  @media (max-width: $breakpoint-2) {
    font-size: 2.4rem;
  }
}

.c-text-featured-body {
  font-size: 1.8rem;
  font-weight: 500;
}

.c-text-featured-3 {
  font-size: 3.6rem;
  font-weight: 500;
  color: $color-white;
  text-shadow: 0 0 10px rgba($color-text, .5);
  color: $color-white;
  text-align: center;

  @media (max-width: $breakpoint-2) {
    font-size: 2.8rem;
  }
}

.c-page-slogan {
  margin-top: 170px;

  @media (max-width: $breakpoint-2) {
    margin-top: 140px;
  }
}

.c-button {
  background: rgba($color-white, .25);
  border: 2px solid $color-gray;
  color: $color-text;
  display: inline-block;
  font-size: 1.8rem;
  font-weight: 500;
  min-width: 300px;
  padding: .75em .25em;
  text-align: center;
  transition: all .2s;

  &:hover {
    text-decoration: none;
    color: $color-primary;
    border-color: $color-primary;
  }

  &[aria-selected='true']{
    border-color: $color-gray-3;
    background-color: $color-gray-3;
    color: $color-white;
  }

  @media (max-width: $breakpoint-1 + 20px) {
    min-width: 100px;
    padding: .75em .75em;
  }

  @media (max-width: $breakpoint-2) {
    font-size: 1.3rem;
  }
}

.c-button[target='_blank'] {
  @extend .u-has-icon;
}

.c-button--inverted {
  color: $color-white;
  background: rgba(0, 0, 0, 0.1);
  border: 2px solid rgba($color-white, .5);

  // &:hover {
  //   border-color: $color-white;
  //   color: $color-white;
  // }
}

.c-button--fill {
  display: block;
}

.c-top-about {

}

.c-top-about-menu-block {

  @media (max-width: $breakpoint-4) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 100px auto 0;
    width: 560px;
  }

  @media (max-width: $breakpoint-2) {
    transform-origin: 50% 50%;
    transform: scale(.8);
  }
}

.c-top-about-menu {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 255px;
  height: 255px;
  border: 2px solid rgba($color-white, .5);
  color: $color-white;
  text-decoration: none;
  transform: rotate(45deg);
  transition: all .2s;

  @media (max-width: $breakpoint-4) {
    position: static;
    flex: 0 0 auto;
    background-color: rgba($color-gray-3, 1);
    width: 200px;
    height: 200px;
    margin: -60px 40px 0;

    &:nth-child(3) {
      margin-left: 180px;
      margin-right: auto;
    }

    &:nth-child(2):not(:hover),
    &:nth-child(5):not(:hover) {
      background-color: rgba($color-primary, 1);
    }
  }

  &:hover {
    border-color: $color-white;
    background-color: $color-white !important;
    color: $color-text;
    text-decoration: none;
  }
}

.c-top-about-menu__inner {
  transform: rotate(-45deg);
}

.c-top-about-menu__icon {
  font-size: 110px;

  @media (max-width: $breakpoint-4) {
    font-size: 90px;
    line-height: 0.5;
  }
}

.c-top-about-menu--type-1 {
  left: -48px;
  top: 262px;
}

.c-top-about-menu--type-2 {
  left: 152px;
  top: 463px;
}

.c-top-about-menu--type-3 {
  left: 353px;
  top: 662px;
}

.c-top-about-menu--type-4 {
  right: 152px;
  top: 463px;
}

.c-top-about-menu--type-5 {
  right: -48px;
  top: 262px;
}

.c-article-list {
}

.c-article-list__item {
  width: 50%;
}

.c-article-list__link {
  background-color: $color-white;
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  color: $color-text;
  display: block;
  padding: 20px;
  transition: all .2s;
  font-weight: 500;

  &[href]:hover {
    text-decoration: none;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15), inset 0 0 0 4px $color-primary;
  }

  @media (max-width: $breakpoint-2) {
    padding: 10px;
  }
}

.c-article-list__label {
  font-size: 1.1rem;
  display: inline-block;
  background-color: $color-gray-2;
  padding: .25em .5em;
  border-radius: 3px;
  font-weight: normal;
  vertical-align: bottom;
  margin-left: 1em;
}

.c-article-list__date {
  font-weight: normal;
}

.c-article-list__title {
  display: block;
  margin-top: .5em;
}

.c-button-wrapper {
  display: inline-block;
  padding: 20px;
  background-color: $color-gray-3;
}

.c-last-button-wrapper {
  margin-top: 2em;
  text-align: center;
}

.c-footer {
  margin-top: 3em;
  position: relative;
}

.c-footer-nav {
  background-color: rgba($color-gray-3, .95);
  padding: 40px 0 30px;
}

.c-footer-layout {
}

.c-footer-layout__item {
  flex: 1;

  @media (max-width: $breakpoint-2) {
    flex: 1 1 50%;
  }
}

.c-footer-list {
  font-weight: 500;
  font-size: 1.4rem;
  padding: 0;

  & > * {
    margin-top: .75em;
  }
}

.c-footer-list--2nd {
  font-weight: normal;
  margin-left: 1em;
  font-size: 1.3rem;
}

.c-footer-link {
  color: $color-white;
  text-decoration: none;

  &:hover {
    color: $color-white;
    text-decoration: underline;
  }
}

.c-footer-bottom {
  text-align: center;
  overflow: hidden;
  background-color: $color-white;
  padding: 1em 0;
  font-weight: normal;
  font-size: 1.4rem;
}

.c-top-recruit__photo {
  position: absolute;
  border-radius: 50px;
  border: 6px solid $color-white;
  display: block;

  @media (max-width: $breakpoint-1) {
    transform: scale(.5);
  }
}

.c-top-recruit__photo--type-1 {
  left: -140px;
  top: 100px;

  @media (max-width: $breakpoint-1) {
    left: 180px;
    top: 560px;
  }
}

.c-top-recruit__photo--type-2 {
  right: 0px;
  top: 450px;
  left: auto;

  @media (max-width: $breakpoint-1) {
    left: 50%;
    top: 810px;
    margin-left: -100px;
}
}

.c-top-recruit__photo--type-3 {
  left: auto;
  right: -140px;
  top: -50px;

  @media (max-width: $breakpoint-1) {
    left: 50%;
    right: auto;
    top: 310px;
    margin-left: -90px;
  }
}

.c-top-recruit-text {
  padding: 20px;
  background: rgba($color-white, .95);
  mix-blend-mode: normal;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  text-align: left;
  font-size: 2.4rem;
  font-weight: 500;

  @media (max-width: $breakpoint-1) {
    padding: 10px;
    font-size: 1.6rem;
  }
}

.c-top-recruit-text--type-1 {
  right: -30px;
  top: -120px;

  @media (max-width: $breakpoint-1) {
    right: auto;
    top: 564px;
    left: 50%;
    margin-left: -100px;
  }
}

.c-top-recruit-text--type-2 {
  left: 0;
  top: 640px;

  @media (max-width: $breakpoint-1) {
    left: 310px;
    top: 820px;
  }
}

.c-top-recruit-text--type-3 {
  right: 220px;
  top: 810px;

  @media (max-width: $breakpoint-1) {
    left: 50%;
    top: 1100px;
    margin-left: -100px;
  }
}

.c-top-recruit-text__featured {
  font-size: 3.6rem;
  color: $color-primary;

  @media (max-width: $breakpoint-1) {
    font-size: 1.2em;
  }
}

.c-page-top-button {
  display: block;
  width: 60px;
  height: 60px;
  text-align: center;
  background-color: $color-primary;
  border-radius: 50%;
  position: fixed;
  bottom: 10px;
  right: 10px;
  transition: transform .2s;

  &.is-sticked {
    position: absolute;
    top: -30px;
    right: 10px;
  }

  &.is-hidden {
    transform: translateY(70px);
  }
}

.c-page-top-button__icon {
  bottom: 0;
  color: $color-white;
  font-size: 35px;
  height: 1em;
  left: 0;
  line-height: 1;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(180deg);
  vertical-align: middle
}

.c-breadcrumbs {
  padding: .75em 0;
  background-color: $color-gray-3;
  font-size: 1.4rem;
  display: block;
  color: $color-white;
}

.c-breadcrumbs-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;

  & > * {
    padding-left: 0;
    display: inline-block;

    &:not(:last-child)::after {
      content: '>';
      display: inline-block;
      padding: 0 .5em;
    }
  }
}

.c-breadcrumbs-link {
  color: $color-white;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.c-content-wrapper {
  background: $color-white;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 30px;
  margin: 30px -30px 0
}

.c-hr {
  border: 0;
  height: 1px;
  background-color: $color-gray-2;
  margin-top: 1.5em;
  display: block;
}

.c-text {
  line-height: 1.8;
}

.c-list {
  padding-left: 0;
  list-style: none;

  & > li,
  & > dt {
    position: relative;
    padding-left: 1.3em;
    font-weight: bold;

    &::before {
      @include i;
      @include i-circle;
      color: $color-primary;
      left: 0;
      position: absolute;
      top: 0.25em;
    }
  }

  & > dt {
    margin-top: 0.5em;
  }

  & > li {
    margin-top: 0.5em;
  }

  & > dd {
    padding-left: 1.3em;
    margin-left: 0;
    margin-top: 0.25em;
  }
}

.c-list--link {

  & > li {

    &::before {
      @include i-arrow-right;
    }
  }
}

.c-list--number {

  & > li {
    padding-left: 2em;

    &::before {
      content: '';
      width: 1.5em;
      height: 1.5em;
      display: block;
      background-color: $color-primary;
      top: 0;
    }

    &::after {
      content: '1';
      color: $color-white;
      width: 1.5em;
      height: 1.5em;
      line-height: 1.5;
      text-align: center;
      left: 0;
      top: 0;
      position: absolute;
    }

    &:nth-child(1)::after {
      content: '1';
    }

    &:nth-child(2)::after {
      content: '2';
    }

    &:nth-child(3)::after {
      content: '3';
    }

    &:nth-child(4)::after {
      content: '4';
    }

    &:nth-child(5)::after {
      content: '5';
    }

    &:nth-child(6)::after {
      content: '7';
    }

    &:nth-child(7)::after {
      content: '7';
    }

    &:nth-child(8)::after {
      content: '8';
    }

    &:nth-child(9)::after {
      content: '9';
    }

    &:nth-child(1)::after {
      content: '1';
    }
  }
}

.c-table {
  width: 100%;
  margin-top: 1em;
  font-weight: normal;

  td,
  th {
    padding: 0.75em 1em;
    border: 1px solid $color-gray-2;
  }

  th {
    text-align: center;
    background-color: rgba($color-primary, .1);
    font-weight: 500;
  }

  @media (max-width: $breakpoint-2) {

    tr {
      display: flex;
      flex-direction: column;
    }

    th {
      border-bottom-width: 0;
    }
  }
}

.c-2nd-nav-layout {
  @include o-grid-gap(30px);

  @media (max-width: $breakpoint-2) {
    @include o-grid-gap(10px);
  }

  & > * {
    width: 33.333333%;

    @media (max-width: $breakpoint-1) {
      width: 50%;
    }
  }
}

.c-about-idea-puzzles {
  height: 500px;
  position: relative;
  transform-origin: 50% 0;

  @media (max-width: 530px) {
    transform: scale(.8);
    margin-bottom: -100px;
  }

  @media (max-width: 420px) {
    transform: scale(.7);
    margin-bottom: -150px;
  }

  @media (max-width: 350px) {
    transform: scale(.6);
    margin-bottom: -200px;
  }
}

.c-about-idea-puzzle {
  position: absolute;
}

.c-about-idea-puzzle--type-1 {
  right: 170px;
  top: 60px;

  @media (max-width: $breakpoint-1 + 20px) {
    right: auto;
    left: 50%;
    margin-left: -102px;
  }
}

.c-about-idea-puzzle--type-2 {
  top: 38px;
  left: 250px;

  @media (max-width: $breakpoint-1 + 20px) {
    left: auto;
    right: 50%;
    margin-right: 20px;
  }
}

.c-about-idea-puzzle--type-3 {
  top: 280px;
  left: 250px;

  @media (max-width: $breakpoint-1 + 20px) {
    right: 50%;
    left: auto;
    margin-right: 20px;
  }
}

.c-strong {
  font-weight: bold;
  color: $color-primary;
}

.c-gmap {
  width: 100%;
  height: 500px;
  margin-top: 1em;
}

.c-global-nav-closer {
  opacity: 0;
  pointer-events: none;
  background-color: #000;
  transition: opacity .2s;
  position: fixed;
  z-index: index($z, global-nav-closer);
  left: 0;
  right: 0;
  top: 0;
  width: 100vw;
  height: 100vh;

  @media (max-width: $breakpoint-3) {

    [aria-expanded='true'] & {
      opacity: 0.5;
      pointer-events: auto;
    }
  }
}

.c-leader-photo {
  max-width: 360px;

  @media (max-width: $breakpoint-2) {
    max-width: 100%;
  }
}

.pagination {
  margin-top: 2em;
  font-size: 1.4rem;
  text-align: center;
}

.page-numbers {
  display: inline-block;
  padding: 0.5em 1em;
  background-color: $color-text;
  color: $color-white;
  border-radius: 4px;
  transition: background .2s;

  &:hover,
  &.current {
    background-color: $color-primary;
    text-decoration: none;
  }
}