/**
 * Grid
 */

.o-grid {
  display: flex;
  flex-wrap: wrap;

  > * {
    flex: 0 1 auto;
  }

  @include o-grid-gap;
}

.o-grid--fill {
  flex-wrap: nowrap;

  > * {
    flex: 1 1 auto;
  }
}

.o-grid--fill-equal {
  flex-wrap: nowrap;

  > * {
    flex: 1 1 0;
  }
}

.o-grid--no-gap {
  @include o-grid-gap(0);
}

/**
 * BOX: Container box
 *
 * Cancel top margin of first child
 */

.o-box {
  @include o-box;
}

.o-space {
  margin-top: 1em;
}

/**
 * Media block
 */

.o-media {
  display: flex;
  @include o-grid-gap;
}

.o-media--flex {

  @media (max-width: $breakpoint-2) {
    flex-direction: column;

    & .o-media__image {
      max-width: 100%;
      text-align: center;
    }
  }
}

.o-media__body {
  flex: 1 1 auto;
}

.o-media__image {
  flex: 0 0 auto;
  max-width: 50%;
}
