@font-face {
  font-family: 'MyYuGothicM';
  font-weight: normal;
  src: local('YuGothic-Medium'),
         /* Postscript name */ local('Yu Gothic Medium'),
         /* for Chrome */ local('YuGothic-Regular'); /* Mediumがない場合 */
}

@font-face {
  font-family: 'MyYuGothicM';
  font-weight: bold;
  src: local('YuGothic-Bold'), /* Postscript name */ local('Yu Gothic'); /* for Chrome */
}

html {
  font-family: $base-font-family;
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;

  @media (max-width: $breakpoint-2) {
    font-size: 1.4rem;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
dl {
  margin-bottom: 0;
}

a {
  color: $color-link;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

img {
  max-width: 100%;
  height: auto;
}