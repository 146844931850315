@font-face {
  font-family: "iconfont";
  src: url('../fonts/iconfont.eot');
  src: url('../fonts/iconfont.eot?#iefix') format('eot'),
    url('../fonts/iconfont.woff') format('woff'),
    url('../fonts/iconfont.ttf') format('truetype'),
    url('../fonts/iconfont.svg#iconfont') format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin i {
  display: inline-block;
  font-family: "iconfont";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin i-lg {
  font-size: 1.3333333333333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}

@mixin i-2x {
  font-size: 2em;
}

@mixin i-3x {
  font-size: 3em;
}

@mixin i-4x {
  font-size: 4em;
}

@mixin i-5x {
  font-size: 5em;
}

@mixin i-fw {
  width: (18em / 14);
  text-align: center;
}

@mixin i-arrow-down {
  content: "\EA01";
}

@mixin i-arrow-right {
  content: "\EA02";
}

@mixin i-caret-right {
  content: "\EA03";
}

@mixin i-circle {
  content: "\EA04";
}

@mixin i-circles-1 {
  content: "\EA05";
}

@mixin i-circles {
  content: "\EA06";
}

@mixin i-comment {
  content: "\EA07";
}

@mixin i-copy {
  content: "\EA08";
}

@mixin i-layers {
  content: "\EA09";
}

@mixin i-map {
  content: "\EA0A";
}

@mixin i-menu {
  content: "\EA0B";
}

@mixin i-puzzle {
  content: "\EA0C";
}


.i {
  @include i;
}

.i-lg {
  @include i-lg;
}

.i-2x {
  @include i-2x;
}

.i-3x {
  @include i-3x;
}

.i-4x {
  @include i-4x;
}

.i-5x {
  @include i-5x;
}

.i-fw {
  @include i-fw;
}

@mixin i-arrow-down {
  content: "\EA01";
}

@mixin i-arrow-right {
  content: "\EA02";
}

@mixin i-caret-right {
  content: "\EA03";
}

@mixin i-circle {
  content: "\EA04";
}

@mixin i-circles-1 {
  content: "\EA05";
}

@mixin i-circles {
  content: "\EA06";
}

@mixin i-comment {
  content: "\EA07";
}

@mixin i-copy {
  content: "\EA08";
}

@mixin i-layers {
  content: "\EA09";
}

@mixin i-map {
  content: "\EA0A";
}

@mixin i-menu {
  content: "\EA0B";
}

@mixin i-puzzle {
  content: "\EA0C";
}


.i-arrow-down:before {
  @include i-arrow-down;
}

.i-arrow-right:before {
  @include i-arrow-right;
}

.i-caret-right:before {
  @include i-caret-right;
}

.i-circle:before {
  @include i-circle;
}

.i-circles-1:before {
  @include i-circles-1;
}

.i-circles:before {
  @include i-circles;
}

.i-comment:before {
  @include i-comment;
}

.i-copy:before {
  @include i-copy;
}

.i-layers:before {
  @include i-layers;
}

.i-map:before {
  @include i-map;
}

.i-menu:before {
  @include i-menu;
}

.i-puzzle:before {
  @include i-puzzle;
}
