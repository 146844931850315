$color-primary: #e31418;
$color-link: $color-primary;
$color-text: #1e1e1e;
$color-white: #fff;
$color-gray: #646464;
$color-gray-2: #dcdcdc;
$color-gray-3: #1e1e1e;
$color-gray-4: #f0f0f0;
$color-gray-5: #dadada;

$z: (
  header,
  global-nav-closer,
  global-nav,
);

$breakpoint-1: 960px;
$breakpoint-2: 640px;
$breakpoint-3: 860px;
$breakpoint-4: 930px;