/**
 * Basa Font Family
 */

@import url(//fonts.googleapis.com/earlyaccess/notosansjp.css);

@font-face {
  font-family: 'MyYuGothicM';
  font-weight: normal;
  src: local('YuGothic-Medium'),
         /* Postscript name */ local('Yu Gothic Medium'),
         /* for Chrome */ local('YuGothic-Regular'); /* Mediumがない場合 */
}

@font-face {
  font-family: 'MyYuGothicM';
  font-weight: bold;
  src: local('YuGothic-Bold'), /* Postscript name */ local('Yu Gothic'); /* for Chrome */
}

$base-font-family: 'Noto Sans JP',
  'Noto Sans CJK JP',
  'Noto Sans CJK Japanese',
  MyYuGothicM, /* Windows 游ゴシック */
  YuGothic, /* OS X 游ゴシック */
  -apple-system, /* OS X, iOS San Francisco */
  BlinkMacSystemFont, /* OS X, iOS Chrome San Francisco */
  'Hiragino Kaku Gothic ProN', /* OS X, iOS ヒラギノ */
  Meiryo, /* Windows メイリオ */
  sans-serif !default;

/**
 * Base Font Size
 *
 * 10pxに対するrem値で設定
 */
$base-font-size: 1.6rem !default;

/**
 * Link
 */
$color-link: #0088ff;

/**
 * Grid Settings
 */
$grid-gap-size: 1em;

@mixin o-grid-gap($gap-x: $grid-gap-size, $gap-y: $gap-x) {
  margin: (-$gap-y) 0 0 (-$gap-x);

  > * {
    padding: $gap-y 0 0 $gap-x;
  }
}

/**
 * BOX: Container box
 *
 * Cancel top margin of first child
 */
@mixin o-box {

  & > *:first-child {
    margin-top: 0;
  }
}