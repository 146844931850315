@charset "UTF-8";
/**
 * Settings & Tools
 *
 * Variables and Mixins
 */
/**
 * Basa Font Family
 */
@import url(//fonts.googleapis.com/earlyaccess/notosansjp.css);
@font-face {
  font-family: 'MyYuGothicM';
  font-weight: normal;
  src: local("YuGothic-Medium"), local("Yu Gothic Medium"), local("YuGothic-Regular");
  /* Mediumがない場合 */
}

@font-face {
  font-family: 'MyYuGothicM';
  font-weight: bold;
  src: local("YuGothic-Bold"), local("Yu Gothic");
  /* for Chrome */
}

/**
 * Base Font Size
 *
 * 10pxに対するrem値で設定
 */
/**
 * Link
 */
/**
 * Grid Settings
 */
/**
 * BOX: Container box
 *
 * Cancel top margin of first child
 */
/**
 * Generic
 */
/*! sanitize.css v5.0.0 | CC0 License | github.com/jonathantneal/sanitize.css */
/* Document (https://html.spec.whatwg.org/multipage/semantics.html#semantics)
   ========================================================================== */
/**
 * 1. Remove repeating backgrounds in all browsers (opinionated).
 * 2. Add box sizing inheritence in all browsers (opinionated).
 */
*,
::before,
::after {
  background-repeat: no-repeat;
  /* 1 */
  box-sizing: inherit;
  /* 2 */
}

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritence in all browsers (opinionated).
 */
::before,
::after {
  text-decoration: inherit;
  /* 1 */
  vertical-align: inherit;
  /* 2 */
}

/**
 * 1. Add border box sizing in all browsers (opinionated).
 * 2. Add the default cursor in all browsers (opinionated).
 * 3. Prevent font size adjustments after orientation changes in IE and iOS.
 */
html {
  box-sizing: border-box;
  /* 1 */
  cursor: default;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */
}

/* Sections (https://html.spec.whatwg.org/multipage/semantics.html#sections)
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: .67em 0;
}

/* Grouping content (https://html.spec.whatwg.org/multipage/semantics.html#grouping-content)
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 * 1. Add the correct display in IE.
 */
figcaption,
figure,
main {
  /* 1 */
  display: block;
}

/**
 * Add the correct margin in IE 8.
 */
figure {
  margin: 1em 40px;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */
nav ol,
nav ul {
  list-style: none;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Text-level semantics (https://html.spec.whatwg.org/multipage/semantics.html#text-level-semantics)
   ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/**
 * 1. Remove the bottom border in Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */
b,
strong {
  font-weight: inherit;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
 * Add the correct font style in Android 4.3-.
 */
dfn {
  font-style: italic;
}

/**
 * Add the correct background and color in IE 9-.
 */
mark {
  background-color: #ffff00;
  color: #000000;
}

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

/*
 * Remove the text shadow on text selections (opinionated).
 * 1. Restore the coloring undone by defining the text shadow (opinionated).
 */
::-moz-selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000000;
  /* 1 */
  text-shadow: none;
}

::selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000000;
  /* 1 */
  text-shadow: none;
}

/* Embedded content (https://html.spec.whatwg.org/multipage/embedded-content.html#embedded-content)
   ========================================================================== */
/*
 * Change the alignment on media elements in all browers (opinionated).
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/**
 * Add the correct display in IE 9-.
 */
audio,
video {
  display: inline-block;
}

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none;
}

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */
svg {
  fill: currentColor;
}

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}

/* Tabular data (https://html.spec.whatwg.org/multipage/tables.html#tables)
   ========================================================================== */
/**
 * Collapse border spacing
 */
table {
  border-collapse: collapse;
}

/* Forms (https://html.spec.whatwg.org/multipage/forms.html#forms)
   ========================================================================== */
/**
 * Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  margin: 0;
}

/**
 * Inherit styling in all browsers (opinionated).
 */
button,
input,
select,
textarea {
  background-color: transparent;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */
}

/**
 * 1. Add the correct display in IE 9-.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
 * 1. Remove the default vertical scrollbar in IE.
 * 2. Change the resize direction on textareas in all browsers (opinionated).
 */
textarea {
  overflow: auto;
  /* 1 */
  resize: vertical;
  /* 2 */
}

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
 */
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/* Interactive elements (https://html.spec.whatwg.org/multipage/forms.html#interactive-elements)
   ========================================================================== */
/*
 * Add the correct display in IE 9-.
 * 1. Add the correct display in Edge, IE, and Firefox.
 */
details,
menu {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/* Scripting (https://html.spec.whatwg.org/multipage/scripting.html#scripting-3)
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
canvas {
  display: inline-block;
}

/**
 * Add the correct display in IE.
 */
template {
  display: none;
}

/* User interaction (https://html.spec.whatwg.org/multipage/interaction.html#editing)
   ========================================================================== */
/*
 * Remove the tapping delay on clickable elements (opinionated).
 * 1. Remove the tapping delay in IE 10.
 */
a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  /* 1 */
  touch-action: manipulation;
}

/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none;
}

/* ARIA (https://w3c.github.io/html-aria/)
   ========================================================================== */
/**
 * Change the cursor on busy elements (opinionated).
 */
[aria-busy="true"] {
  cursor: progress;
}

/*
 * Change the cursor on control elements (opinionated).
 */
[aria-controls] {
  cursor: pointer;
}

/*
 * Change the display on visually hidden accessible elements (opinionated).
 */
[aria-hidden="false"][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements (opinionated).
 */
[aria-disabled] {
  cursor: default;
}

/**
 * Base
 */
@font-face {
  font-family: 'MyYuGothicM';
  font-weight: normal;
  src: local("YuGothic-Medium"), local("Yu Gothic Medium"), local("YuGothic-Regular");
  /* Mediumがない場合 */
}

@font-face {
  font-family: 'MyYuGothicM';
  font-weight: bold;
  src: local("YuGothic-Bold"), local("Yu Gothic");
  /* for Chrome */
}

html {
  font-family: "Noto Sans JP", "Noto Sans CJK JP", "Noto Sans CJK Japanese", MyYuGothicM, YuGothic, -apple-system, BlinkMacSystemFont, "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
}

@media (max-width: 640px) {
  body {
    font-size: 1.4rem;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
dl {
  margin-bottom: 0;
}

a {
  color: #e31418;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

img {
  max-width: 100%;
  height: auto;
}

/**
 * Objects
 */
/**
 * Grid
 */
.o-grid {
  display: flex;
  flex-wrap: wrap;
  margin: -1em 0 0 -1em;
}

.o-grid > * {
  flex: 0 1 auto;
}

.o-grid > * {
  padding: 1em 0 0 1em;
}

.o-grid--fill {
  flex-wrap: nowrap;
}

.o-grid--fill > * {
  flex: 1 1 auto;
}

.o-grid--fill-equal {
  flex-wrap: nowrap;
}

.o-grid--fill-equal > * {
  flex: 1 1 0;
}

.o-grid--no-gap {
  margin: 0 0 0 0;
}

.o-grid--no-gap > * {
  padding: 0 0 0 0;
}

/**
 * BOX: Container box
 *
 * Cancel top margin of first child
 */
.o-box > *:first-child {
  margin-top: 0;
}

.o-space {
  margin-top: 1em;
}

/**
 * Media block
 */
.o-media {
  display: flex;
  margin: -1em 0 0 -1em;
}

.o-media > * {
  padding: 1em 0 0 1em;
}

@media (max-width: 640px) {
  .o-media--flex {
    flex-direction: column;
  }
  .o-media--flex .o-media__image {
    max-width: 100%;
    text-align: center;
  }
}

.o-media__body {
  flex: 1 1 auto;
}

.o-media__image {
  flex: 0 0 auto;
  max-width: 50%;
}

@font-face {
  font-family: "iconfont";
  src: url("../fonts/iconfont.eot");
  src: url("../fonts/iconfont.eot?#iefix") format("eot"), url("../fonts/iconfont.woff") format("woff"), url("../fonts/iconfont.ttf") format("truetype"), url("../fonts/iconfont.svg#iconfont") format("svg");
  font-weight: normal;
  font-style: normal;
}

.i {
  display: inline-block;
  font-family: "iconfont";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.i-lg {
  font-size: 1.3333333333333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}

.i-2x {
  font-size: 2em;
}

.i-3x {
  font-size: 3em;
}

.i-4x {
  font-size: 4em;
}

.i-5x {
  font-size: 5em;
}

.i-fw {
  width: 1.285714em;
  text-align: center;
}

.i-arrow-down:before {
  content: "\EA01";
}

.i-arrow-right:before {
  content: "\EA02";
}

.i-caret-right:before {
  content: "\EA03";
}

.i-circle:before {
  content: "\EA04";
}

.i-circles-1:before {
  content: "\EA05";
}

.i-circles:before {
  content: "\EA06";
}

.i-comment:before {
  content: "\EA07";
}

.i-copy:before {
  content: "\EA08";
}

.i-layers:before {
  content: "\EA09";
}

.i-map:before {
  content: "\EA0A";
}

.i-menu:before {
  content: "\EA0B";
}

.i-puzzle:before {
  content: "\EA0C";
}

@media (max-width: 640px) {
  .o-grid--flex {
    flex-direction: column;
  }
}

.o-grid--type-2 {
  margin: -30px 0 0 -30px;
}

.o-grid--type-2 > * {
  padding: 30px 0 0 30px;
}

.o-width-1 {
  width: 50%;
}

@media (max-width: 600px) {
  .o-width-1 {
    width: 100%;
  }
}

.o-width-2 {
  width: 33.333333%;
}

@media (max-width: 640px) {
  .o-width-2 {
    width: 100%;
  }
}

.o-container {
  width: 960px;
  margin: 0 auto;
}

@media (max-width: 980px) {
  .o-container {
    width: auto;
    padding: 0 10px;
  }
}

.o-after-icon::after {
  display: inline-block;
  font-family: "iconfont";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\EA08";
  margin-left: 0.5em;
  transition: all .2s;
}

.o-media--type-2 {
  margin: -30px 0 0 -30px;
}

.o-media--type-2 > * {
  padding: 30px 0 0 30px;
}

/**
 * Components
 */
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}

.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

body[data-aos-duration='50'] [data-aos], [data-aos][data-aos][data-aos-duration='50'] {
  transition-duration: 50ms;
}

body[data-aos-delay='50'] [data-aos], [data-aos][data-aos][data-aos-delay='50'] {
  transition-delay: 0;
}

body[data-aos-delay='50'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='50'].aos-animate {
  transition-delay: 50ms;
}

body[data-aos-duration='100'] [data-aos], [data-aos][data-aos][data-aos-duration='100'] {
  transition-duration: 100ms;
}

body[data-aos-delay='100'] [data-aos], [data-aos][data-aos][data-aos-delay='100'] {
  transition-delay: 0;
}

body[data-aos-delay='100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='100'].aos-animate {
  transition-delay: 100ms;
}

body[data-aos-duration='150'] [data-aos], [data-aos][data-aos][data-aos-duration='150'] {
  transition-duration: 150ms;
}

body[data-aos-delay='150'] [data-aos], [data-aos][data-aos][data-aos-delay='150'] {
  transition-delay: 0;
}

body[data-aos-delay='150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='150'].aos-animate {
  transition-delay: 150ms;
}

body[data-aos-duration='200'] [data-aos], [data-aos][data-aos][data-aos-duration='200'] {
  transition-duration: 200ms;
}

body[data-aos-delay='200'] [data-aos], [data-aos][data-aos][data-aos-delay='200'] {
  transition-delay: 0;
}

body[data-aos-delay='200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='200'].aos-animate {
  transition-delay: 200ms;
}

body[data-aos-duration='250'] [data-aos], [data-aos][data-aos][data-aos-duration='250'] {
  transition-duration: 250ms;
}

body[data-aos-delay='250'] [data-aos], [data-aos][data-aos][data-aos-delay='250'] {
  transition-delay: 0;
}

body[data-aos-delay='250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='250'].aos-animate {
  transition-delay: 250ms;
}

body[data-aos-duration='300'] [data-aos], [data-aos][data-aos][data-aos-duration='300'] {
  transition-duration: 300ms;
}

body[data-aos-delay='300'] [data-aos], [data-aos][data-aos][data-aos-delay='300'] {
  transition-delay: 0;
}

body[data-aos-delay='300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='300'].aos-animate {
  transition-delay: 300ms;
}

body[data-aos-duration='350'] [data-aos], [data-aos][data-aos][data-aos-duration='350'] {
  transition-duration: 350ms;
}

body[data-aos-delay='350'] [data-aos], [data-aos][data-aos][data-aos-delay='350'] {
  transition-delay: 0;
}

body[data-aos-delay='350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='350'].aos-animate {
  transition-delay: 350ms;
}

body[data-aos-duration='400'] [data-aos], [data-aos][data-aos][data-aos-duration='400'] {
  transition-duration: 400ms;
}

body[data-aos-delay='400'] [data-aos], [data-aos][data-aos][data-aos-delay='400'] {
  transition-delay: 0;
}

body[data-aos-delay='400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='400'].aos-animate {
  transition-delay: 400ms;
}

body[data-aos-duration='450'] [data-aos], [data-aos][data-aos][data-aos-duration='450'] {
  transition-duration: 450ms;
}

body[data-aos-delay='450'] [data-aos], [data-aos][data-aos][data-aos-delay='450'] {
  transition-delay: 0;
}

body[data-aos-delay='450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='450'].aos-animate {
  transition-delay: 450ms;
}

body[data-aos-duration='500'] [data-aos], [data-aos][data-aos][data-aos-duration='500'] {
  transition-duration: 500ms;
}

body[data-aos-delay='500'] [data-aos], [data-aos][data-aos][data-aos-delay='500'] {
  transition-delay: 0;
}

body[data-aos-delay='500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='500'].aos-animate {
  transition-delay: 500ms;
}

body[data-aos-duration='550'] [data-aos], [data-aos][data-aos][data-aos-duration='550'] {
  transition-duration: 550ms;
}

body[data-aos-delay='550'] [data-aos], [data-aos][data-aos][data-aos-delay='550'] {
  transition-delay: 0;
}

body[data-aos-delay='550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='550'].aos-animate {
  transition-delay: 550ms;
}

body[data-aos-duration='600'] [data-aos], [data-aos][data-aos][data-aos-duration='600'] {
  transition-duration: 600ms;
}

body[data-aos-delay='600'] [data-aos], [data-aos][data-aos][data-aos-delay='600'] {
  transition-delay: 0;
}

body[data-aos-delay='600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='600'].aos-animate {
  transition-delay: 600ms;
}

body[data-aos-duration='650'] [data-aos], [data-aos][data-aos][data-aos-duration='650'] {
  transition-duration: 650ms;
}

body[data-aos-delay='650'] [data-aos], [data-aos][data-aos][data-aos-delay='650'] {
  transition-delay: 0;
}

body[data-aos-delay='650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='650'].aos-animate {
  transition-delay: 650ms;
}

body[data-aos-duration='700'] [data-aos], [data-aos][data-aos][data-aos-duration='700'] {
  transition-duration: 700ms;
}

body[data-aos-delay='700'] [data-aos], [data-aos][data-aos][data-aos-delay='700'] {
  transition-delay: 0;
}

body[data-aos-delay='700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='700'].aos-animate {
  transition-delay: 700ms;
}

body[data-aos-duration='750'] [data-aos], [data-aos][data-aos][data-aos-duration='750'] {
  transition-duration: 750ms;
}

body[data-aos-delay='750'] [data-aos], [data-aos][data-aos][data-aos-delay='750'] {
  transition-delay: 0;
}

body[data-aos-delay='750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='750'].aos-animate {
  transition-delay: 750ms;
}

body[data-aos-duration='800'] [data-aos], [data-aos][data-aos][data-aos-duration='800'] {
  transition-duration: 800ms;
}

body[data-aos-delay='800'] [data-aos], [data-aos][data-aos][data-aos-delay='800'] {
  transition-delay: 0;
}

body[data-aos-delay='800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='800'].aos-animate {
  transition-delay: 800ms;
}

body[data-aos-duration='850'] [data-aos], [data-aos][data-aos][data-aos-duration='850'] {
  transition-duration: 850ms;
}

body[data-aos-delay='850'] [data-aos], [data-aos][data-aos][data-aos-delay='850'] {
  transition-delay: 0;
}

body[data-aos-delay='850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='850'].aos-animate {
  transition-delay: 850ms;
}

body[data-aos-duration='900'] [data-aos], [data-aos][data-aos][data-aos-duration='900'] {
  transition-duration: 900ms;
}

body[data-aos-delay='900'] [data-aos], [data-aos][data-aos][data-aos-delay='900'] {
  transition-delay: 0;
}

body[data-aos-delay='900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='900'].aos-animate {
  transition-delay: 900ms;
}

body[data-aos-duration='950'] [data-aos], [data-aos][data-aos][data-aos-duration='950'] {
  transition-duration: 950ms;
}

body[data-aos-delay='950'] [data-aos], [data-aos][data-aos][data-aos-delay='950'] {
  transition-delay: 0;
}

body[data-aos-delay='950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='950'].aos-animate {
  transition-delay: 950ms;
}

body[data-aos-duration='1000'] [data-aos], [data-aos][data-aos][data-aos-duration='1000'] {
  transition-duration: 1000ms;
}

body[data-aos-delay='1000'] [data-aos], [data-aos][data-aos][data-aos-delay='1000'] {
  transition-delay: 0;
}

body[data-aos-delay='1000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1000'].aos-animate {
  transition-delay: 1000ms;
}

body[data-aos-duration='1050'] [data-aos], [data-aos][data-aos][data-aos-duration='1050'] {
  transition-duration: 1050ms;
}

body[data-aos-delay='1050'] [data-aos], [data-aos][data-aos][data-aos-delay='1050'] {
  transition-delay: 0;
}

body[data-aos-delay='1050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1050'].aos-animate {
  transition-delay: 1050ms;
}

body[data-aos-duration='1100'] [data-aos], [data-aos][data-aos][data-aos-duration='1100'] {
  transition-duration: 1100ms;
}

body[data-aos-delay='1100'] [data-aos], [data-aos][data-aos][data-aos-delay='1100'] {
  transition-delay: 0;
}

body[data-aos-delay='1100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1100'].aos-animate {
  transition-delay: 1100ms;
}

body[data-aos-duration='1150'] [data-aos], [data-aos][data-aos][data-aos-duration='1150'] {
  transition-duration: 1150ms;
}

body[data-aos-delay='1150'] [data-aos], [data-aos][data-aos][data-aos-delay='1150'] {
  transition-delay: 0;
}

body[data-aos-delay='1150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1150'].aos-animate {
  transition-delay: 1150ms;
}

body[data-aos-duration='1200'] [data-aos], [data-aos][data-aos][data-aos-duration='1200'] {
  transition-duration: 1200ms;
}

body[data-aos-delay='1200'] [data-aos], [data-aos][data-aos][data-aos-delay='1200'] {
  transition-delay: 0;
}

body[data-aos-delay='1200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1200'].aos-animate {
  transition-delay: 1200ms;
}

body[data-aos-duration='1250'] [data-aos], [data-aos][data-aos][data-aos-duration='1250'] {
  transition-duration: 1250ms;
}

body[data-aos-delay='1250'] [data-aos], [data-aos][data-aos][data-aos-delay='1250'] {
  transition-delay: 0;
}

body[data-aos-delay='1250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1250'].aos-animate {
  transition-delay: 1250ms;
}

body[data-aos-duration='1300'] [data-aos], [data-aos][data-aos][data-aos-duration='1300'] {
  transition-duration: 1300ms;
}

body[data-aos-delay='1300'] [data-aos], [data-aos][data-aos][data-aos-delay='1300'] {
  transition-delay: 0;
}

body[data-aos-delay='1300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1300'].aos-animate {
  transition-delay: 1300ms;
}

body[data-aos-duration='1350'] [data-aos], [data-aos][data-aos][data-aos-duration='1350'] {
  transition-duration: 1350ms;
}

body[data-aos-delay='1350'] [data-aos], [data-aos][data-aos][data-aos-delay='1350'] {
  transition-delay: 0;
}

body[data-aos-delay='1350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1350'].aos-animate {
  transition-delay: 1350ms;
}

body[data-aos-duration='1400'] [data-aos], [data-aos][data-aos][data-aos-duration='1400'] {
  transition-duration: 1400ms;
}

body[data-aos-delay='1400'] [data-aos], [data-aos][data-aos][data-aos-delay='1400'] {
  transition-delay: 0;
}

body[data-aos-delay='1400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1400'].aos-animate {
  transition-delay: 1400ms;
}

body[data-aos-duration='1450'] [data-aos], [data-aos][data-aos][data-aos-duration='1450'] {
  transition-duration: 1450ms;
}

body[data-aos-delay='1450'] [data-aos], [data-aos][data-aos][data-aos-delay='1450'] {
  transition-delay: 0;
}

body[data-aos-delay='1450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1450'].aos-animate {
  transition-delay: 1450ms;
}

body[data-aos-duration='1500'] [data-aos], [data-aos][data-aos][data-aos-duration='1500'] {
  transition-duration: 1500ms;
}

body[data-aos-delay='1500'] [data-aos], [data-aos][data-aos][data-aos-delay='1500'] {
  transition-delay: 0;
}

body[data-aos-delay='1500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1500'].aos-animate {
  transition-delay: 1500ms;
}

body[data-aos-duration='1550'] [data-aos], [data-aos][data-aos][data-aos-duration='1550'] {
  transition-duration: 1550ms;
}

body[data-aos-delay='1550'] [data-aos], [data-aos][data-aos][data-aos-delay='1550'] {
  transition-delay: 0;
}

body[data-aos-delay='1550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1550'].aos-animate {
  transition-delay: 1550ms;
}

body[data-aos-duration='1600'] [data-aos], [data-aos][data-aos][data-aos-duration='1600'] {
  transition-duration: 1600ms;
}

body[data-aos-delay='1600'] [data-aos], [data-aos][data-aos][data-aos-delay='1600'] {
  transition-delay: 0;
}

body[data-aos-delay='1600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1600'].aos-animate {
  transition-delay: 1600ms;
}

body[data-aos-duration='1650'] [data-aos], [data-aos][data-aos][data-aos-duration='1650'] {
  transition-duration: 1650ms;
}

body[data-aos-delay='1650'] [data-aos], [data-aos][data-aos][data-aos-delay='1650'] {
  transition-delay: 0;
}

body[data-aos-delay='1650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1650'].aos-animate {
  transition-delay: 1650ms;
}

body[data-aos-duration='1700'] [data-aos], [data-aos][data-aos][data-aos-duration='1700'] {
  transition-duration: 1700ms;
}

body[data-aos-delay='1700'] [data-aos], [data-aos][data-aos][data-aos-delay='1700'] {
  transition-delay: 0;
}

body[data-aos-delay='1700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1700'].aos-animate {
  transition-delay: 1700ms;
}

body[data-aos-duration='1750'] [data-aos], [data-aos][data-aos][data-aos-duration='1750'] {
  transition-duration: 1750ms;
}

body[data-aos-delay='1750'] [data-aos], [data-aos][data-aos][data-aos-delay='1750'] {
  transition-delay: 0;
}

body[data-aos-delay='1750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1750'].aos-animate {
  transition-delay: 1750ms;
}

body[data-aos-duration='1800'] [data-aos], [data-aos][data-aos][data-aos-duration='1800'] {
  transition-duration: 1800ms;
}

body[data-aos-delay='1800'] [data-aos], [data-aos][data-aos][data-aos-delay='1800'] {
  transition-delay: 0;
}

body[data-aos-delay='1800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1800'].aos-animate {
  transition-delay: 1800ms;
}

body[data-aos-duration='1850'] [data-aos], [data-aos][data-aos][data-aos-duration='1850'] {
  transition-duration: 1850ms;
}

body[data-aos-delay='1850'] [data-aos], [data-aos][data-aos][data-aos-delay='1850'] {
  transition-delay: 0;
}

body[data-aos-delay='1850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1850'].aos-animate {
  transition-delay: 1850ms;
}

body[data-aos-duration='1900'] [data-aos], [data-aos][data-aos][data-aos-duration='1900'] {
  transition-duration: 1900ms;
}

body[data-aos-delay='1900'] [data-aos], [data-aos][data-aos][data-aos-delay='1900'] {
  transition-delay: 0;
}

body[data-aos-delay='1900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1900'].aos-animate {
  transition-delay: 1900ms;
}

body[data-aos-duration='1950'] [data-aos], [data-aos][data-aos][data-aos-duration='1950'] {
  transition-duration: 1950ms;
}

body[data-aos-delay='1950'] [data-aos], [data-aos][data-aos][data-aos-delay='1950'] {
  transition-delay: 0;
}

body[data-aos-delay='1950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1950'].aos-animate {
  transition-delay: 1950ms;
}

body[data-aos-duration='2000'] [data-aos], [data-aos][data-aos][data-aos-duration='2000'] {
  transition-duration: 2000ms;
}

body[data-aos-delay='2000'] [data-aos], [data-aos][data-aos][data-aos-delay='2000'] {
  transition-delay: 0;
}

body[data-aos-delay='2000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2000'].aos-animate {
  transition-delay: 2000ms;
}

body[data-aos-duration='2050'] [data-aos], [data-aos][data-aos][data-aos-duration='2050'] {
  transition-duration: 2050ms;
}

body[data-aos-delay='2050'] [data-aos], [data-aos][data-aos][data-aos-delay='2050'] {
  transition-delay: 0;
}

body[data-aos-delay='2050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2050'].aos-animate {
  transition-delay: 2050ms;
}

body[data-aos-duration='2100'] [data-aos], [data-aos][data-aos][data-aos-duration='2100'] {
  transition-duration: 2100ms;
}

body[data-aos-delay='2100'] [data-aos], [data-aos][data-aos][data-aos-delay='2100'] {
  transition-delay: 0;
}

body[data-aos-delay='2100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2100'].aos-animate {
  transition-delay: 2100ms;
}

body[data-aos-duration='2150'] [data-aos], [data-aos][data-aos][data-aos-duration='2150'] {
  transition-duration: 2150ms;
}

body[data-aos-delay='2150'] [data-aos], [data-aos][data-aos][data-aos-delay='2150'] {
  transition-delay: 0;
}

body[data-aos-delay='2150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2150'].aos-animate {
  transition-delay: 2150ms;
}

body[data-aos-duration='2200'] [data-aos], [data-aos][data-aos][data-aos-duration='2200'] {
  transition-duration: 2200ms;
}

body[data-aos-delay='2200'] [data-aos], [data-aos][data-aos][data-aos-delay='2200'] {
  transition-delay: 0;
}

body[data-aos-delay='2200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2200'].aos-animate {
  transition-delay: 2200ms;
}

body[data-aos-duration='2250'] [data-aos], [data-aos][data-aos][data-aos-duration='2250'] {
  transition-duration: 2250ms;
}

body[data-aos-delay='2250'] [data-aos], [data-aos][data-aos][data-aos-delay='2250'] {
  transition-delay: 0;
}

body[data-aos-delay='2250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2250'].aos-animate {
  transition-delay: 2250ms;
}

body[data-aos-duration='2300'] [data-aos], [data-aos][data-aos][data-aos-duration='2300'] {
  transition-duration: 2300ms;
}

body[data-aos-delay='2300'] [data-aos], [data-aos][data-aos][data-aos-delay='2300'] {
  transition-delay: 0;
}

body[data-aos-delay='2300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2300'].aos-animate {
  transition-delay: 2300ms;
}

body[data-aos-duration='2350'] [data-aos], [data-aos][data-aos][data-aos-duration='2350'] {
  transition-duration: 2350ms;
}

body[data-aos-delay='2350'] [data-aos], [data-aos][data-aos][data-aos-delay='2350'] {
  transition-delay: 0;
}

body[data-aos-delay='2350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2350'].aos-animate {
  transition-delay: 2350ms;
}

body[data-aos-duration='2400'] [data-aos], [data-aos][data-aos][data-aos-duration='2400'] {
  transition-duration: 2400ms;
}

body[data-aos-delay='2400'] [data-aos], [data-aos][data-aos][data-aos-delay='2400'] {
  transition-delay: 0;
}

body[data-aos-delay='2400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2400'].aos-animate {
  transition-delay: 2400ms;
}

body[data-aos-duration='2450'] [data-aos], [data-aos][data-aos][data-aos-duration='2450'] {
  transition-duration: 2450ms;
}

body[data-aos-delay='2450'] [data-aos], [data-aos][data-aos][data-aos-delay='2450'] {
  transition-delay: 0;
}

body[data-aos-delay='2450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2450'].aos-animate {
  transition-delay: 2450ms;
}

body[data-aos-duration='2500'] [data-aos], [data-aos][data-aos][data-aos-duration='2500'] {
  transition-duration: 2500ms;
}

body[data-aos-delay='2500'] [data-aos], [data-aos][data-aos][data-aos-delay='2500'] {
  transition-delay: 0;
}

body[data-aos-delay='2500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2500'].aos-animate {
  transition-delay: 2500ms;
}

body[data-aos-duration='2550'] [data-aos], [data-aos][data-aos][data-aos-duration='2550'] {
  transition-duration: 2550ms;
}

body[data-aos-delay='2550'] [data-aos], [data-aos][data-aos][data-aos-delay='2550'] {
  transition-delay: 0;
}

body[data-aos-delay='2550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2550'].aos-animate {
  transition-delay: 2550ms;
}

body[data-aos-duration='2600'] [data-aos], [data-aos][data-aos][data-aos-duration='2600'] {
  transition-duration: 2600ms;
}

body[data-aos-delay='2600'] [data-aos], [data-aos][data-aos][data-aos-delay='2600'] {
  transition-delay: 0;
}

body[data-aos-delay='2600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2600'].aos-animate {
  transition-delay: 2600ms;
}

body[data-aos-duration='2650'] [data-aos], [data-aos][data-aos][data-aos-duration='2650'] {
  transition-duration: 2650ms;
}

body[data-aos-delay='2650'] [data-aos], [data-aos][data-aos][data-aos-delay='2650'] {
  transition-delay: 0;
}

body[data-aos-delay='2650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2650'].aos-animate {
  transition-delay: 2650ms;
}

body[data-aos-duration='2700'] [data-aos], [data-aos][data-aos][data-aos-duration='2700'] {
  transition-duration: 2700ms;
}

body[data-aos-delay='2700'] [data-aos], [data-aos][data-aos][data-aos-delay='2700'] {
  transition-delay: 0;
}

body[data-aos-delay='2700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2700'].aos-animate {
  transition-delay: 2700ms;
}

body[data-aos-duration='2750'] [data-aos], [data-aos][data-aos][data-aos-duration='2750'] {
  transition-duration: 2750ms;
}

body[data-aos-delay='2750'] [data-aos], [data-aos][data-aos][data-aos-delay='2750'] {
  transition-delay: 0;
}

body[data-aos-delay='2750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2750'].aos-animate {
  transition-delay: 2750ms;
}

body[data-aos-duration='2800'] [data-aos], [data-aos][data-aos][data-aos-duration='2800'] {
  transition-duration: 2800ms;
}

body[data-aos-delay='2800'] [data-aos], [data-aos][data-aos][data-aos-delay='2800'] {
  transition-delay: 0;
}

body[data-aos-delay='2800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2800'].aos-animate {
  transition-delay: 2800ms;
}

body[data-aos-duration='2850'] [data-aos], [data-aos][data-aos][data-aos-duration='2850'] {
  transition-duration: 2850ms;
}

body[data-aos-delay='2850'] [data-aos], [data-aos][data-aos][data-aos-delay='2850'] {
  transition-delay: 0;
}

body[data-aos-delay='2850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2850'].aos-animate {
  transition-delay: 2850ms;
}

body[data-aos-duration='2900'] [data-aos], [data-aos][data-aos][data-aos-duration='2900'] {
  transition-duration: 2900ms;
}

body[data-aos-delay='2900'] [data-aos], [data-aos][data-aos][data-aos-delay='2900'] {
  transition-delay: 0;
}

body[data-aos-delay='2900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2900'].aos-animate {
  transition-delay: 2900ms;
}

body[data-aos-duration='2950'] [data-aos], [data-aos][data-aos][data-aos-duration='2950'] {
  transition-duration: 2950ms;
}

body[data-aos-delay='2950'] [data-aos], [data-aos][data-aos][data-aos-delay='2950'] {
  transition-delay: 0;
}

body[data-aos-delay='2950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2950'].aos-animate {
  transition-delay: 2950ms;
}

body[data-aos-duration='3000'] [data-aos], [data-aos][data-aos][data-aos-duration='3000'] {
  transition-duration: 3000ms;
}

body[data-aos-delay='3000'] [data-aos], [data-aos][data-aos][data-aos-delay='3000'] {
  transition-delay: 0;
}

body[data-aos-delay='3000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='3000'].aos-animate {
  transition-delay: 3000ms;
}

body[data-aos-easing="linear"] [data-aos], [data-aos][data-aos][data-aos-easing="linear"] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
}

body[data-aos-easing="ease"] [data-aos], [data-aos][data-aos][data-aos-easing="ease"] {
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}

body[data-aos-easing="ease-in"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in"] {
  transition-timing-function: cubic-bezier(0.42, 0, 1, 1);
}

body[data-aos-easing="ease-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out"] {
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1);
}

body[data-aos-easing="ease-in-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out"] {
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}

body[data-aos-easing="ease-in-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-back"] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

body[data-aos-easing="ease-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-back"] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

body[data-aos-easing="ease-in-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-back"] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

body[data-aos-easing="ease-in-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-sine"] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}

body[data-aos-easing="ease-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-sine"] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}

body[data-aos-easing="ease-in-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-sine"] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

body[data-aos-easing="ease-in-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quad"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

body[data-aos-easing="ease-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quad"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

body[data-aos-easing="ease-in-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quad"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

body[data-aos-easing="ease-in-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-cubic"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

body[data-aos-easing="ease-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-cubic"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

body[data-aos-easing="ease-in-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-cubic"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

body[data-aos-easing="ease-in-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quart"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

body[data-aos-easing="ease-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quart"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

body[data-aos-easing="ease-in-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quart"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

/**
 * Fade animations:
 * fade
 * fade-up, fade-down, fade-left, fade-right
 * fade-up-right, fade-up-left, fade-down-right, fade-down-left
 */
[data-aos^='fade'][data-aos^='fade'] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^='fade'][data-aos^='fade'].aos-animate {
  opacity: 1;
  transform: translate(0, 0);
}

[data-aos='fade-up'] {
  transform: translate(0, 100px);
}

[data-aos='fade-down'] {
  transform: translate(0, -100px);
}

[data-aos='fade-right'] {
  transform: translate(-100px, 0);
}

[data-aos='fade-left'] {
  transform: translate(100px, 0);
}

[data-aos='fade-up-right'] {
  transform: translate(-100px, 100px);
}

[data-aos='fade-up-left'] {
  transform: translate(100px, 100px);
}

[data-aos='fade-down-right'] {
  transform: translate(-100px, -100px);
}

[data-aos='fade-down-left'] {
  transform: translate(100px, -100px);
}

/**
 * Zoom animations:
 * zoom-in, zoom-in-up, zoom-in-down, zoom-in-left, zoom-in-right
 * zoom-out, zoom-out-up, zoom-out-down, zoom-out-left, zoom-out-right
 */
[data-aos^='zoom'][data-aos^='zoom'] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^='zoom'][data-aos^='zoom'].aos-animate {
  opacity: 1;
  transform: translate(0, 0) scale(1);
}

[data-aos='zoom-in'] {
  transform: scale(0.6);
}

[data-aos='zoom-in-up'] {
  transform: translate(0, 100px) scale(0.6);
}

[data-aos='zoom-in-down'] {
  transform: translate(0, -100px) scale(0.6);
}

[data-aos='zoom-in-right'] {
  transform: translate(-100px, 0) scale(0.6);
}

[data-aos='zoom-in-left'] {
  transform: translate(100px, 0) scale(0.6);
}

[data-aos='zoom-out'] {
  transform: scale(1.2);
}

[data-aos='zoom-out-up'] {
  transform: translate(0, 100px) scale(1.2);
}

[data-aos='zoom-out-down'] {
  transform: translate(0, -100px) scale(1.2);
}

[data-aos='zoom-out-right'] {
  transform: translate(-100px, 0) scale(1.2);
}

[data-aos='zoom-out-left'] {
  transform: translate(100px, 0) scale(1.2);
}

/**
 * Slide animations
 */
[data-aos^='slide'][data-aos^='slide'] {
  transition-property: transform;
}

[data-aos^='slide'][data-aos^='slide'].aos-animate {
  transform: translate(0, 0);
}

[data-aos='slide-up'] {
  transform: translateY(100%);
}

[data-aos='slide-down'] {
  transform: translateY(-100%);
}

[data-aos='slide-right'] {
  transform: translateX(-100%);
}

[data-aos='slide-left'] {
  transform: translateX(100%);
}

/**
 * Flip animations:
 * flip-left, flip-right, flip-up, flip-down
 */
[data-aos^='flip'][data-aos^='flip'] {
  backface-visibility: hidden;
  transition-property: transform;
}

[data-aos='flip-left'] {
  transform: perspective(2500px) rotateY(-100deg);
}

[data-aos='flip-left'].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos='flip-right'] {
  transform: perspective(2500px) rotateY(100deg);
}

[data-aos='flip-right'].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos='flip-up'] {
  transform: perspective(2500px) rotateX(-100deg);
}

[data-aos='flip-up'].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

[data-aos='flip-down'] {
  transform: perspective(2500px) rotateX(100deg);
}

[data-aos='flip-down'].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

.c-page {
  background: url(../img/background-pattern.png) 50% -280px repeat #fff;
  position: relative;
  padding-top: 80px;
  min-width: 960px;
  overflow: hidden;
}

.is-ie .c-page {
  background-image: url(../img/background-pattern.png);
}

@media (max-width: 980px) {
  .c-page {
    min-width: 0;
  }
}

@media (max-width: 860px) {
  .c-page:not(.c-page--top) {
    padding-top: 60px;
  }
}

@media (max-width: 600px) {
  .c-page:not(.c-page--top) {
    padding-top: 60px;
  }
  .admin-bar .c-page:not(.c-page--top) {
    padding-top: 14px;
  }
}

.c-page--top {
  padding-top: 5170px;
}

.c-header {
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.admin-bar .c-header {
  top: 32px;
}

@media (max-width: 782px) {
  .admin-bar .c-header {
    top: 46px;
  }
}

@media (max-width: 600px) {
  .admin-bar .c-header {
    top: 0;
  }
}

@media (max-width: 860px) {
  .c-header {
    padding: 5px;
  }
}

.c-header__layout {
  align-items: center;
}

.c-header__logo {
  max-width: 100%;
}

.c-global-nav-opener {
  display: none;
}

@media (max-width: 860px) {
  .c-global-nav-opener {
    display: block;
    font-size: 30px;
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
    line-height: 1;
    height: 1em;
  }
}

.c-global-nav {
  padding: 0;
  font-size: 1.4rem;
  font-weight: 500;
  flex-wrap: nowrap;
  justify-content: flex-end;
  z-index: 3;
  margin: -8px 0 0 -8px;
}

.c-global-nav > * {
  padding: 8px 0 0 8px;
}

@media (max-width: 980px) {
  .c-global-nav {
    font-size: 1.2rem;
  }
}

@media (max-width: 860px) {
  .c-global-nav {
    margin: 0 0 0 0;
    font-size: 1.4rem;
    position: fixed;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #fff;
    top: 0;
    right: auto;
    left: 0;
    width: 70vw;
    height: 100vh;
    overflow: auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transition: transform .2s;
    transform: translateX(-70vw);
  }
  .c-global-nav > * {
    padding: 0 0 0 0;
  }
  [aria-expanded='true'] .c-global-nav {
    transform: translateX(0);
  }
}

.c-global-nav__link {
  display: block;
  height: 80px;
  display: flex;
  align-items: center;
  padding: 5px;
  text-decoration: none;
  color: #1e1e1e;
  transition: all .2s;
  position: relative;
}

.c-global-nav__link::after {
  content: '';
  display: block;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  transition: all .2s;
  background-color: #e31418;
  position: absolute;
}

.c-global-nav__link:hover, .c-global-nav__link[aria-selected='true'] {
  color: #e31418;
  text-decoration: none;
}

.c-global-nav__link:hover::after, .c-global-nav__link[aria-selected='true']::after {
  height: 4px;
}

@media (max-width: 860px) {
  .c-global-nav__link {
    height: auto;
    padding: 1em .5em 1em 1em;
  }
  .c-global-nav__link::after {
    left: 0;
    top: 0;
    height: 100%;
    width: 0;
  }
  .c-global-nav__link:hover::after, .c-global-nav__link[aria-selected='true']::after {
    left: 0;
    top: 0;
    height: 100%;
    width: 5px;
  }
}

.c-top-section {
  text-align: center;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  width: 960px;
  transform-origin: 50% 0;
}

@media (max-width: 960px) {
  .c-top-section {
    margin-left: calc((-960px + 100vw) / 2);
  }
}

.c-top-section--intro {
  top: 80px;
}

@media (max-width: 960px) {
  .c-top-section--intro {
    top: calc( 100vw * 80 / 960);
  }
}

@media (max-width: 440px) {
  .c-top-section--intro {
    transform: scale(0.9);
  }
}

@media (max-width: 400px) {
  .c-top-section--intro {
    transform: scale(0.8);
  }
}

@media (max-width: 360px) {
  .c-top-section--intro {
    transform: scale(0.7);
  }
}

.c-top-section--next {
  top: 630px;
}

@media (max-width: 740px) {
  .c-top-section--next {
    transform: scale(0.9);
    top: 680px;
  }
}

@media (max-width: 680px) {
  .c-top-section--next {
    transform: scale(0.8);
    top: 730px;
  }
}

@media (max-width: 600px) {
  .c-top-section--next {
    transform: scale(0.7);
    top: 780px;
  }
}

@media (max-width: 520px) {
  .c-top-section--next {
    transform: scale(0.6);
    top: 850px;
  }
}

@media (max-width: 450px) {
  .c-top-section--next {
    transform: scale(0.5);
    top: 930px;
  }
}

@media (max-width: 380px) {
  .c-top-section--next {
    transform: scale(0.4);
  }
}

.c-top-section--about {
  top: 1330px;
}

@media (max-width: 930px) {
  .c-top-section--about {
    top: calc(1330px * .95);
  }
}

.c-top-section--service {
  top: 2530px;
}

.c-top-section--recruit {
  top: 3730px;
}

.c-top-section--news {
  top: 4930px;
}

.c-top-service__photo {
  width: 700px;
  height: 700px;
  position: absolute;
}

@media (max-width: 640px) {
  .c-top-service__photo {
    width: 400px;
    height: auto;
    margin: auto;
  }
}

.c-top-service__photo--type-1 {
  left: -270px;
  top: -160px;
}

@media (max-width: 640px) {
  .c-top-service__photo--type-1 {
    left: 0;
    top: 590px;
    right: 0;
    transform: translate(-50%, -50%);
  }
}

.c-top-service__photo--type-2 {
  left: 0;
  right: 0;
  margin: auto;
  top: 240px;
}

@media (max-width: 640px) {
  .c-top-service__photo--type-2 {
    left: 0;
    top: 590px;
    right: 0;
  }
}

.c-top-service__photo--type-3 {
  right: -270px;
  top: -160px;
}

@media (max-width: 640px) {
  .c-top-service__photo--type-3 {
    left: 0;
    top: 590px;
    right: 0;
    transform: translate(50%, -50%);
  }
}

.c-top-section-dia {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.9);
  width: 566px;
  height: 566px;
  top: -93px;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(45deg);
}

.c-top-section-dia__inner {
  transform: rotate(-45deg);
  padding-top: 21px;
}

.c-top-intro__logo {
  width: 231px;
  height: auto;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 80px;
}

.c-top-intro__title {
  width: 377px;
  height: 177px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 177px;
}

.c-top-intro__charactor {
  position: absolute;
  left: 195px;
  top: -1px;
}

.c-top-intro__text {
  position: absolute;
  top: 329px;
  font-size: 1.8rem;
  margin: 0;
  width: 100%;
}

.c-top-intro__link {
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 473px;
}

.c-top-intro__logo-part {
  position: absolute;
  left: 365px;
  top: 120px;
}

.c-scroll-sign {
  text-align: center;
  text-decoration: none;
}

.c-scroll-sign:hover {
  text-decoration: none;
}

.c-scroll-sign__icon {
  display: block;
  font-size: 8rem;
  height: .8em;
  line-height: 1;
  vertical-align: middle;
}

.c-top-next__puzzle {
  background-size: contain;
  color: #fff;
  font-size: 2.4rem;
  height: 480px;
  justify-content: center;
  padding-top: 160px;
  position: absolute;
  text-align: center;
  text-decoration: none;
  width: 430px;
}

.c-top-next__puzzle:hover {
  text-decoration: none;
}

.c-top-next__puzzle--yellow {
  background-image: url(../img/puzzle-piece-yellow.svg);
  top: 0;
  left: 23px;
}

.c-top-next__puzzle--red {
  background-image: url(../img/puzzle-piece-red.svg);
  top: 243px;
  left: 0;
  right: 0;
  margin: auto;
}

.c-top-next__puzzle--blue {
  background-image: url(../img/puzzle-piece-blue.svg);
  top: 0;
  right: 23px;
}

.c-top-next__puzzle-link {
  color: #fff;
}

.c-top-next__puzzle-link:hover {
  text-decoration: none;
}

.c-heading {
  text-align: center;
}

.c-heading::after {
  content: url(../img/x.svg);
  display: block;
  margin: 26px auto 0;
}

.c-heading__title {
  font-size: 6.4rem;
  font-weight: bold;
  display: block;
}

@media (max-width: 640px) {
  .c-heading__title {
    font-size: 3.6rem;
  }
}

.c-heading__body {
  font-size: 1.8rem;
  font-weight: 500;
  display: block;
}

@media (max-width: 640px) {
  .c-heading__body {
    font-size: 1.6rem;
  }
}

.c-heading-2 {
  font-weight: bold;
  font-size: 2.4rem;
  border-bottom: 2px solid #e31418;
  padding-bottom: 0.5em;
  margin-top: 1.5em;
}

@media (max-width: 640px) {
  .c-heading-2 {
    font-size: 2rem;
  }
}

.c-heading-3 {
  font-size: 2rem;
  font-weight: bold;
  background-color: #f0f0f0;
  padding: .5em;
  border-left: 5px solid #e31418;
  margin-top: 1.5em;
}

.c-heading-4 {
  font-size: 1.8rem;
  font-weight: bold;
  margin-top: 1.5em;
  position: relative;
  padding-left: 1.5em;
}

.c-heading-4::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  width: 1em;
  height: 1em;
  top: 0.25em;
  border-radius: 50%;
  border: 3px solid #e31418;
}

.c-text-featured {
  text-align: center;
  font-size: 2.4rem;
  font-weight: 500;
}

@media (max-width: 640px) {
  .c-text-featured {
    font-size: 2rem;
  }
}

.c-text-featured__strong {
  font-size: 3.6rem;
  color: #e31418;
}

.c-text-featured-2 {
  margin-top: 0.5em;
  font-size: 3.6rem;
  font-weight: 500;
}

@media (max-width: 640px) {
  .c-text-featured-2 {
    font-size: 2.4rem;
  }
}

.c-text-featured-body {
  font-size: 1.8rem;
  font-weight: 500;
}

.c-text-featured-3 {
  font-size: 3.6rem;
  font-weight: 500;
  color: #fff;
  text-shadow: 0 0 10px rgba(30, 30, 30, 0.5);
  color: #fff;
  text-align: center;
}

@media (max-width: 640px) {
  .c-text-featured-3 {
    font-size: 2.8rem;
  }
}

.c-page-slogan {
  margin-top: 170px;
}

@media (max-width: 640px) {
  .c-page-slogan {
    margin-top: 140px;
  }
}

.c-button {
  background: rgba(255, 255, 255, 0.25);
  border: 2px solid #646464;
  color: #1e1e1e;
  display: inline-block;
  font-size: 1.8rem;
  font-weight: 500;
  min-width: 300px;
  padding: .75em .25em;
  text-align: center;
  transition: all .2s;
}

.c-button:hover {
  text-decoration: none;
  color: #e31418;
  border-color: #e31418;
}

.c-button[aria-selected='true'] {
  border-color: #1e1e1e;
  background-color: #1e1e1e;
  color: #fff;
}

@media (max-width: 980px) {
  .c-button {
    min-width: 100px;
    padding: .75em .75em;
  }
}

@media (max-width: 640px) {
  .c-button {
    font-size: 1.3rem;
  }
}

.c-button--inverted {
  color: #fff;
  background: rgba(0, 0, 0, 0.1);
  border: 2px solid rgba(255, 255, 255, 0.5);
}

.c-button--fill {
  display: block;
}

@media (max-width: 930px) {
  .c-top-about-menu-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 100px auto 0;
    width: 560px;
  }
}

@media (max-width: 640px) {
  .c-top-about-menu-block {
    transform-origin: 50% 50%;
    transform: scale(0.8);
  }
}

.c-top-about-menu {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 255px;
  height: 255px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  color: #fff;
  text-decoration: none;
  transform: rotate(45deg);
  transition: all .2s;
}

@media (max-width: 930px) {
  .c-top-about-menu {
    position: static;
    flex: 0 0 auto;
    background-color: #1e1e1e;
    width: 200px;
    height: 200px;
    margin: -60px 40px 0;
  }
  .c-top-about-menu:nth-child(3) {
    margin-left: 180px;
    margin-right: auto;
  }
  .c-top-about-menu:nth-child(2):not(:hover), .c-top-about-menu:nth-child(5):not(:hover) {
    background-color: #e31418;
  }
}

.c-top-about-menu:hover {
  border-color: #fff;
  background-color: #fff !important;
  color: #1e1e1e;
  text-decoration: none;
}

.c-top-about-menu__inner {
  transform: rotate(-45deg);
}

.c-top-about-menu__icon {
  font-size: 110px;
}

@media (max-width: 930px) {
  .c-top-about-menu__icon {
    font-size: 90px;
    line-height: 0.5;
  }
}

.c-top-about-menu--type-1 {
  left: -48px;
  top: 262px;
}

.c-top-about-menu--type-2 {
  left: 152px;
  top: 463px;
}

.c-top-about-menu--type-3 {
  left: 353px;
  top: 662px;
}

.c-top-about-menu--type-4 {
  right: 152px;
  top: 463px;
}

.c-top-about-menu--type-5 {
  right: -48px;
  top: 262px;
}

.c-article-list__item {
  width: 50%;
}

.c-article-list__link {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  color: #1e1e1e;
  display: block;
  padding: 20px;
  transition: all .2s;
  font-weight: 500;
}

.c-article-list__link[href]:hover {
  text-decoration: none;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15), inset 0 0 0 4px #e31418;
}

@media (max-width: 640px) {
  .c-article-list__link {
    padding: 10px;
  }
}

.c-article-list__label {
  font-size: 1.1rem;
  display: inline-block;
  background-color: #dcdcdc;
  padding: .25em .5em;
  border-radius: 3px;
  font-weight: normal;
  vertical-align: bottom;
  margin-left: 1em;
}

.c-article-list__date {
  font-weight: normal;
}

.c-article-list__title {
  display: block;
  margin-top: .5em;
}

.c-button-wrapper {
  display: inline-block;
  padding: 20px;
  background-color: #1e1e1e;
}

.c-last-button-wrapper {
  margin-top: 2em;
  text-align: center;
}

.c-footer {
  margin-top: 3em;
  position: relative;
}

.c-footer-nav {
  background-color: rgba(30, 30, 30, 0.95);
  padding: 40px 0 30px;
}

.c-footer-layout__item {
  flex: 1;
}

@media (max-width: 640px) {
  .c-footer-layout__item {
    flex: 1 1 50%;
  }
}

.c-footer-list {
  font-weight: 500;
  font-size: 1.4rem;
  padding: 0;
}

.c-footer-list > * {
  margin-top: .75em;
}

.c-footer-list--2nd {
  font-weight: normal;
  margin-left: 1em;
  font-size: 1.3rem;
}

.c-footer-link {
  color: #fff;
  text-decoration: none;
}

.c-footer-link:hover {
  color: #fff;
  text-decoration: underline;
}

.c-footer-bottom {
  text-align: center;
  overflow: hidden;
  background-color: #fff;
  padding: 1em 0;
  font-weight: normal;
  font-size: 1.4rem;
}

.c-top-recruit__photo {
  position: absolute;
  border-radius: 50px;
  border: 6px solid #fff;
  display: block;
}

@media (max-width: 960px) {
  .c-top-recruit__photo {
    transform: scale(0.5);
  }
}

.c-top-recruit__photo--type-1 {
  left: -140px;
  top: 100px;
}

@media (max-width: 960px) {
  .c-top-recruit__photo--type-1 {
    left: 180px;
    top: 560px;
  }
}

.c-top-recruit__photo--type-2 {
  right: 0px;
  top: 450px;
  left: auto;
}

@media (max-width: 960px) {
  .c-top-recruit__photo--type-2 {
    left: 50%;
    top: 810px;
    margin-left: -100px;
  }
}

.c-top-recruit__photo--type-3 {
  left: auto;
  right: -140px;
  top: -50px;
}

@media (max-width: 960px) {
  .c-top-recruit__photo--type-3 {
    left: 50%;
    right: auto;
    top: 310px;
    margin-left: -90px;
  }
}

.c-top-recruit-text {
  padding: 20px;
  background: rgba(255, 255, 255, 0.95);
  mix-blend-mode: normal;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  text-align: left;
  font-size: 2.4rem;
  font-weight: 500;
}

@media (max-width: 960px) {
  .c-top-recruit-text {
    padding: 10px;
    font-size: 1.6rem;
  }
}

.c-top-recruit-text--type-1 {
  right: -30px;
  top: -120px;
}

@media (max-width: 960px) {
  .c-top-recruit-text--type-1 {
    right: auto;
    top: 564px;
    left: 50%;
    margin-left: -100px;
  }
}

.c-top-recruit-text--type-2 {
  left: 0;
  top: 640px;
}

@media (max-width: 960px) {
  .c-top-recruit-text--type-2 {
    left: 310px;
    top: 820px;
  }
}

.c-top-recruit-text--type-3 {
  right: 220px;
  top: 810px;
}

@media (max-width: 960px) {
  .c-top-recruit-text--type-3 {
    left: 50%;
    top: 1100px;
    margin-left: -100px;
  }
}

.c-top-recruit-text__featured {
  font-size: 3.6rem;
  color: #e31418;
}

@media (max-width: 960px) {
  .c-top-recruit-text__featured {
    font-size: 1.2em;
  }
}

.c-page-top-button {
  display: block;
  width: 60px;
  height: 60px;
  text-align: center;
  background-color: #e31418;
  border-radius: 50%;
  position: fixed;
  bottom: 10px;
  right: 10px;
  transition: transform .2s;
}

.c-page-top-button.is-sticked {
  position: absolute;
  top: -30px;
  right: 10px;
}

.c-page-top-button.is-hidden {
  transform: translateY(70px);
}

.c-page-top-button__icon {
  bottom: 0;
  color: #fff;
  font-size: 35px;
  height: 1em;
  left: 0;
  line-height: 1;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(180deg);
  vertical-align: middle;
}

.c-breadcrumbs {
  padding: .75em 0;
  background-color: #1e1e1e;
  font-size: 1.4rem;
  display: block;
  color: #fff;
}

.c-breadcrumbs-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
}

.c-breadcrumbs-list > * {
  padding-left: 0;
  display: inline-block;
}

.c-breadcrumbs-list > *:not(:last-child)::after {
  content: '>';
  display: inline-block;
  padding: 0 .5em;
}

.c-breadcrumbs-link {
  color: #fff;
  text-decoration: none;
}

.c-breadcrumbs-link:hover {
  text-decoration: underline;
}

.c-content-wrapper {
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 30px;
  margin: 30px -30px 0;
}

.c-hr {
  border: 0;
  height: 1px;
  background-color: #dcdcdc;
  margin-top: 1.5em;
  display: block;
}

.c-text {
  line-height: 1.8;
}

.c-list {
  padding-left: 0;
  list-style: none;
}

.c-list > li,
.c-list > dt {
  position: relative;
  padding-left: 1.3em;
  font-weight: bold;
}

.c-list > li::before,
.c-list > dt::before {
  display: inline-block;
  font-family: "iconfont";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\EA04";
  color: #e31418;
  left: 0;
  position: absolute;
  top: 0.25em;
}

.c-list > dt {
  margin-top: 0.5em;
}

.c-list > li {
  margin-top: 0.5em;
}

.c-list > dd {
  padding-left: 1.3em;
  margin-left: 0;
  margin-top: 0.25em;
}

.c-list--link > li::before {
  content: "\EA02";
}

.c-list--number > li {
  padding-left: 2em;
}

.c-list--number > li::before {
  content: '';
  width: 1.5em;
  height: 1.5em;
  display: block;
  background-color: #e31418;
  top: 0;
}

.c-list--number > li::after {
  content: '1';
  color: #fff;
  width: 1.5em;
  height: 1.5em;
  line-height: 1.5;
  text-align: center;
  left: 0;
  top: 0;
  position: absolute;
}

.c-list--number > li:nth-child(1)::after {
  content: '1';
}

.c-list--number > li:nth-child(2)::after {
  content: '2';
}

.c-list--number > li:nth-child(3)::after {
  content: '3';
}

.c-list--number > li:nth-child(4)::after {
  content: '4';
}

.c-list--number > li:nth-child(5)::after {
  content: '5';
}

.c-list--number > li:nth-child(6)::after {
  content: '7';
}

.c-list--number > li:nth-child(7)::after {
  content: '7';
}

.c-list--number > li:nth-child(8)::after {
  content: '8';
}

.c-list--number > li:nth-child(9)::after {
  content: '9';
}

.c-list--number > li:nth-child(1)::after {
  content: '1';
}

.c-table {
  width: 100%;
  margin-top: 1em;
  font-weight: normal;
}

.c-table td,
.c-table th {
  padding: 0.75em 1em;
  border: 1px solid #dcdcdc;
}

.c-table th {
  text-align: center;
  background-color: rgba(227, 20, 24, 0.1);
  font-weight: 500;
}

@media (max-width: 640px) {
  .c-table tr {
    display: flex;
    flex-direction: column;
  }
  .c-table th {
    border-bottom-width: 0;
  }
}

.c-2nd-nav-layout {
  margin: -30px 0 0 -30px;
}

.c-2nd-nav-layout > * {
  padding: 30px 0 0 30px;
}

@media (max-width: 640px) {
  .c-2nd-nav-layout {
    margin: -10px 0 0 -10px;
  }
  .c-2nd-nav-layout > * {
    padding: 10px 0 0 10px;
  }
}

.c-2nd-nav-layout > * {
  width: 33.333333%;
}

@media (max-width: 960px) {
  .c-2nd-nav-layout > * {
    width: 50%;
  }
}

.c-about-idea-puzzles {
  height: 500px;
  position: relative;
  transform-origin: 50% 0;
}

@media (max-width: 530px) {
  .c-about-idea-puzzles {
    transform: scale(0.8);
    margin-bottom: -100px;
  }
}

@media (max-width: 420px) {
  .c-about-idea-puzzles {
    transform: scale(0.7);
    margin-bottom: -150px;
  }
}

@media (max-width: 350px) {
  .c-about-idea-puzzles {
    transform: scale(0.6);
    margin-bottom: -200px;
  }
}

.c-about-idea-puzzle {
  position: absolute;
}

.c-about-idea-puzzle--type-1 {
  right: 170px;
  top: 60px;
}

@media (max-width: 980px) {
  .c-about-idea-puzzle--type-1 {
    right: auto;
    left: 50%;
    margin-left: -102px;
  }
}

.c-about-idea-puzzle--type-2 {
  top: 38px;
  left: 250px;
}

@media (max-width: 980px) {
  .c-about-idea-puzzle--type-2 {
    left: auto;
    right: 50%;
    margin-right: 20px;
  }
}

.c-about-idea-puzzle--type-3 {
  top: 280px;
  left: 250px;
}

@media (max-width: 980px) {
  .c-about-idea-puzzle--type-3 {
    right: 50%;
    left: auto;
    margin-right: 20px;
  }
}

.c-strong {
  font-weight: bold;
  color: #e31418;
}

.c-gmap {
  width: 100%;
  height: 500px;
  margin-top: 1em;
}

.c-global-nav-closer {
  opacity: 0;
  pointer-events: none;
  background-color: #000;
  transition: opacity .2s;
  position: fixed;
  z-index: 2;
  left: 0;
  right: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
}

@media (max-width: 860px) {
  [aria-expanded='true'] .c-global-nav-closer {
    opacity: 0.5;
    pointer-events: auto;
  }
}

.c-leader-photo {
  max-width: 360px;
}

@media (max-width: 640px) {
  .c-leader-photo {
    max-width: 100%;
  }
}

.pagination {
  margin-top: 2em;
  font-size: 1.4rem;
  text-align: center;
}

.page-numbers {
  display: inline-block;
  padding: 0.5em 1em;
  background-color: #1e1e1e;
  color: #fff;
  border-radius: 4px;
  transition: background .2s;
}

.page-numbers:hover, .page-numbers.current {
  background-color: #e31418;
  text-decoration: none;
}

/**
 * Trumps
 *
 * Utility styles
 */
.u-centered {
  text-align: center;
}

.u-justified {
  text-align: justify;
}

.u-left {
  text-align: left;
}

.u-right {
  text-align: right;
}

.u-center-block {
  position: relative;
}

.u-center-block__content,
.u-absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.u-center-block__content--vertical {
  left: auto;
  transform: translateY(-50%);
}

.u-center-block__content--horizontal {
  top: auto;
  transform: translateX(-50%);
}

.u-no-overflow {
  overflow: hidden;
}

.u-letter-box-super {
  padding-top: 3em;
  padding-bottom: 3em;
}

.u-letter-box-xlarge {
  padding-top: 2em;
  padding-bottom: 2em;
}

.u-letter-box-large {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}

.u-letter-box-medium {
  padding-top: 1em;
  padding-bottom: 1em;
}

.u-letter-box-small {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.u-letter-box-xsmall {
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}

.u-letter-box-tiny {
  padding-top: 0.125em;
  padding-bottom: 0.125em;
}

.u-letter-box-none {
  padding-top: 0;
  padding-bottom: 0;
}

.u-pillar-box-super {
  padding-right: 3em;
  padding-left: 3em;
}

.u-pillar-box-xlarge {
  padding-right: 2em;
  padding-left: 2em;
}

.u-pillar-box-large {
  padding-right: 1.5em;
  padding-left: 1.5em;
}

.u-pillar-box-medium {
  padding-right: 1em;
  padding-left: 1em;
}

.u-pillar-box-small {
  padding-right: 0.5em;
  padding-left: 0.5em;
}

.u-pillar-box-xsmall {
  padding-right: 0.25em;
  padding-left: 0.25em;
}

.u-pillar-box-tiny {
  padding-right: 0.125em;
  padding-left: 0.125em;
}

.u-pillar-box-none {
  padding-right: 0;
  padding-left: 0;
}

.u-window-box-super {
  padding-top: 3em;
  padding-bottom: 3em;
  padding-right: 3em;
  padding-left: 3em;
}

.u-window-box-xlarge {
  padding-top: 2em;
  padding-bottom: 2em;
  padding-right: 2em;
  padding-left: 2em;
}

.u-window-box-large {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  padding-right: 1.5em;
  padding-left: 1.5em;
}

.u-window-box-medium {
  padding-top: 1em;
  padding-bottom: 1em;
  padding-right: 1em;
  padding-left: 1em;
}

.u-window-box-small {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-right: 0.5em;
  padding-left: 0.5em;
}

.u-window-box-xsmall {
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  padding-right: 0.25em;
  padding-left: 0.25em;
}

.u-window-box-tiny {
  padding-top: 0.125em;
  padding-bottom: 0.125em;
  padding-right: 0.125em;
  padding-left: 0.125em;
}

.u-window-box-none {
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
  padding-left: 0;
}

.u-high,
.u-higher,
.u-highest {
  border: 0;
}

.u-high {
  box-shadow: 0 0 1px rgba(17, 17, 17, 0.6), 0 5px 10px -3px rgba(17, 17, 17, 0.4);
}

.u-higher {
  box-shadow: 0 0 1px rgba(17, 17, 17, 0.6), 0 10px 25px -4px rgba(17, 17, 17, 0.4);
}

.u-highest {
  box-shadow: 0 0 1px rgba(17, 17, 17, 0.6), 0 20px 55px -8px rgba(17, 17, 17, 0.4);
}

.u-super {
  font-size: 2em;
}

.u-xlarge {
  font-size: 1.5em;
}

.u-large {
  font-size: 1.25em;
}

.u-medium {
  font-size: 1em;
}

.u-small {
  font-size: 0.8em;
}

.u-xsmall {
  font-size: 0.67em;
}

.u-visible {
  visibility: visible;
}

.u-invisible {
  visibility: hidden;
}

.u-display-none {
  display: none;
}

.u-display-initial {
  display: initial;
}

.u-display-inline {
  display: inline;
}

.u-display-inline-block {
  display: inline-block;
}

.u-display-block {
  display: block;
}

.u-display-table {
  display: table;
}

.u-display-table-cell {
  display: table-cell;
}

.u-display-flex {
  display: flex;
}

.u-display-inline-flex {
  display: inline-flex;
}

.u-sample-row {
  background-color: #cbc9dd;
}

.u-sample-col {
  background-color: #b8dbfc;
  min-height: 100px;
}

.u-sample-col--type-2 {
  min-height: 200px;
}

.u-has-icon::after, .c-button[target='_blank']::after {
  display: inline-block;
  font-family: "iconfont";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\EA08";
  color: #dadada;
  margin-left: 0.25em;
  vertical-align: baseline;
  transition: all .2s;
}

.u-has-icon:hover::after, .c-button[target='_blank']:hover::after {
  color: #e31418;
}

.u-breaked {
  margin-top: 2em;
}

@media (max-width: 640px) {
  .u-only-pc {
    display: none;
  }
}

.alignleft {
  text-align: left;
}

.alignright {
  text-align: right;
}

.aligncenter {
  text-align: center;
}

.u-screen-reader-text,
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
  /* Many screen reader and browser combinations announce broken words as they would appear visually. */
}

/*# sourceMappingURL=main.css.map */