.u-sample-row {
  background-color: #cbc9dd;
}

.u-sample-col {
  background-color: #b8dbfc;
  min-height: 100px;
}

.u-sample-col--type-2 {
  min-height: 200px;
}

.u-has-icon {

  &::after {
    @include i;
    @include i-copy;
    color: $color-gray-5;
    margin-left: 0.25em;
    vertical-align: baseline;
    transition: all .2s;
  }

  &:hover::after {
    color: $color-primary;
  }
}

.u-breaked {
  margin-top: 2em;
}

.u-only-pc {

  @media (max-width: $breakpoint-2) {
    display: none;
  }
}

// for WordPress MCE

.alignleft {
  text-align: left;
}

.alignright {
  text-align: right;
}

.aligncenter {
  text-align: center;
}

.u-screen-reader-text,
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
  /* Many screen reader and browser combinations announce broken words as they would appear visually. */
}
