.o-grid--flex {

  @media (max-width: $breakpoint-2) {
    flex-direction: column;
  }
}

.o-grid--type-2 {
  @include o-grid-gap(30px);
}

.o-width-1 {
  width: 50%;

  @media (max-width: 600px) {
    width: 100%;
  }
}

.o-width-2 {
  width: 33.333333%;

  @media (max-width: $breakpoint-2) {
    width: 100%;
  }
}

.o-container {
  width: 960px;
  margin: 0 auto;

  @media (max-width: $breakpoint-1 + 20px) {
    width: auto;
    padding: 0 10px;
  }
}

.o-after-icon {

  &::after {
    @include i;
    @include i-copy;
    margin-left: 0.5em;
    transition: all .2s;
  }
}

.o-media--type-2 {
  @include o-grid-gap(30px);
}